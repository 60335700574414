import { render, staticRenderFns } from "./ChangePassword.vue?vue&type=template&id=3cb03c22&scoped=true&"
import script from "./ChangePassword.vue?vue&type=script&lang=ts&"
export * from "./ChangePassword.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cb03c22",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VForm } from 'vuetify/lib/components/VForm';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VForm,VTab,VTabItem,VTabs,VTabsItems,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/sam-mono/sam-mono/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3cb03c22')) {
      api.createRecord('3cb03c22', component.options)
    } else {
      api.reload('3cb03c22', component.options)
    }
    module.hot.accept("./ChangePassword.vue?vue&type=template&id=3cb03c22&scoped=true&", function () {
      api.rerender('3cb03c22', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Auth/ChangePassword/ChangePassword.vue"
export default component.exports