







































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Vue from 'vue';
import ErrorAlert from '../Utility/ErrorAlert.vue';
import SuccessMessage from '../Utility/SuccessMessage.vue';
import { FlowService } from '@services/flow.service';
import { LoginService } from '@services/login.service';

export default Vue.extend({
    name: 'ChangePassword',
    components: {
        ErrorAlert,
        SuccessMessage
    },
    data() {
        return {
            eyeToggle: true,
            changePasswordModel: null,
            passwordChanged: false,
            formValid: false,
            passwordRules: [
                (v) => !!v || 'Password is required',
                (v) => (v && v.length >= 9) || 'Must be atleast 9 characters',
                (v) => /[A-Z]/.test(v) || 'Must contain atleast 1 capital letter',
                (v) => /\d/.test(v) || 'Must contain atleast 1 number',
                (v) => /[^A-Za-z 0-9]/.test(v) || 'Must contain special characters', // by checking presence of any  character other than numbers and aplphabets
                (v) => !/\s/.test(v) || 'Cannot contain spaces',
            ],
            passwordSuccessMessage: 'Password changed successfully.',
            passwordErrorMessage: '',
            // ory fields
            action: null,
            csrf_token: null,
            uid: null,
            email: null
        };
    },
    props: {

    },
    computed: {
        passwordEyeIcon() {
            return this.eyeToggle ? 'mdi-eye' : 'mdi-eye-off'
        },
        passwordInputTypeToggle() {
            return this.eyeToggle ? 'password' : 'text';
        }
    },
    methods: {
        toggleEyeIcon() {
            this.eyeToggle = !this.eyeToggle;
        },
        async getRecoveryFlow() {
            try {
                const flowResponse = await FlowService.fetchFlow(this.$route.query.flow, 'settings');

                if (flowResponse.error) return this._handleFlowError(flowResponse);

                this._updateOryStateVariables(flowResponse);

                /**
                 * Need to check this after we've fetched the email
                 */
                await this._isUserDisabled(this.email);

                this._handleFlowUiMessages(flowResponse);

            } catch (error) {
                this._handleNonRecoverableError(error);
            }
        },
        _updateOryStateVariables(flowResponse) {
            this.action = flowResponse.ui.action;
            this.csrf_token = flowResponse.ui.nodes[0].attributes.value;
            this.uid = flowResponse.identity.id;
            this.email = flowResponse.identity.traits.email;
        },
        async _isUserDisabled(email) {
            try {
                if (email) {
                    const status = await LoginService.isUserDisabled(email);
                    if (status && status.successMessage) {
                        this.$notify.warning('Your account is either disabled or unauthorized. Try login again or contact admin.');
                        LSS.clear();
                        window.location.href = Vue.prototype.$out_url
                    }
                }
            } catch (error) {
                console.log(error);
            }
        },
        _handleFlowUiMessages(flowResponse) {
            if (flowResponse.state == 'success') return this.passwordChanged = true;

            if (flowResponse.state == 'show_form') {
                const ERROR_CORRECTABLE_BY_USER = flowResponse?.ui?.nodes[8] && flowResponse?.ui?.nodes[8]?.messages && flowResponse?.ui?.nodes[8]?.messages[0] && flowResponse?.ui?.nodes[8]?.messages[0].id == 4000005;
                if (ERROR_CORRECTABLE_BY_USER) {
                    return this.passwordErrorMessage = flowResponse?.ui?.nodes[8]?.messages[0].text
                }
            }
        },
        _handleFlowError(flowResponse) {
            switch (flowResponse.error?.code) {
                case 410:
                    return this.passwordErrorMessage = 'Session expired.';
                case 401:
                    return this.passwordErrorMessage = 'Session invalid.';
                default:
                    return this._handleNonRecoverableError(flowResponse);
            }
        },
        _handleNonRecoverableError(error) {
            this.passwordErrorMessage = 'Something seems wrong. Please try later.';
        }
    },
    beforeMount() {
        const flow = this.$route.query.flow;
        if (!flow) {
            return this.$router.push('/login');
        } else {
            this.getRecoveryFlow();
        }
    }
});
