var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.passwordChanged
      ? _c(
          "div",
          [
            _c(
              "v-tabs",
              {
                attrs: { grow: "" },
                model: {
                  value: _vm.changePasswordModel,
                  callback: function ($$v) {
                    _vm.changePasswordModel = $$v
                  },
                  expression: "changePasswordModel",
                },
              },
              [
                _c("v-tab", [
                  _c("span", { staticClass: "gotham-medium-font" }, [
                    _vm._v("SET A PASSWORD"),
                  ]),
                ]),
              ],
              1
            ),
            _c(
              "v-tabs-items",
              {
                model: {
                  value: _vm.changePasswordModel,
                  callback: function ($$v) {
                    _vm.changePasswordModel = $$v
                  },
                  expression: "changePasswordModel",
                },
              },
              [
                _c(
                  "v-tab-item",
                  [
                    _c("ErrorAlert", {
                      attrs: { errorMessage: _vm.passwordErrorMessage },
                    }),
                    _c(
                      "v-form",
                      {
                        attrs: { action: _vm.action, method: "POST" },
                        model: {
                          value: _vm.formValid,
                          callback: function ($$v) {
                            _vm.formValid = $$v
                          },
                          expression: "formValid",
                        },
                      },
                      [
                        _c("input", {
                          attrs: { type: "hidden", name: "csrf_token" },
                          domProps: { value: _vm.csrf_token },
                        }),
                        _c("input", {
                          attrs: { type: "hidden", name: "traits.email" },
                          domProps: { value: _vm.email },
                        }),
                        _c("v-text-field", {
                          attrs: {
                            rules: _vm.passwordRules,
                            maxlength: 30,
                            minlength: 8,
                            type: _vm.passwordInputTypeToggle,
                            height: "56",
                            "hide-details": "",
                            "append-icon": _vm.passwordEyeIcon,
                            label: "Password",
                            name: "password",
                            required: "",
                          },
                          on: {
                            "click:append": function ($event) {
                              return _vm.toggleEyeIcon()
                            },
                          },
                        }),
                        _c("v-text-field", {
                          attrs: {
                            rules: _vm.passwordRules,
                            maxlength: 30,
                            minlength: 8,
                            type: _vm.passwordInputTypeToggle,
                            height: "56",
                            "hide-details": "",
                            "append-icon": _vm.passwordEyeIcon,
                            label: "Confirm Password",
                            required: "",
                          },
                          on: {
                            "click:append": function ($event) {
                              return _vm.toggleEyeIcon()
                            },
                          },
                        }),
                        _c(
                          "v-btn",
                          {
                            staticClass: "mt-10",
                            attrs: {
                              disabled: !_vm.formValid,
                              type: "submit",
                              value: "password",
                              name: "method",
                              color: "primary",
                              width: "100%",
                            },
                          },
                          [_c("strong", [_vm._v("CHANGE PASSWORD")])]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "d-flex justify-center" },
          [
            _c("SuccessMessage", {
              attrs: { successMessage: _vm.passwordSuccessMessage },
            }),
          ],
          1
        ),
    _c(
      "p",
      { staticClass: "font-16 medium-font text-center mt-10" },
      [
        _c(
          "router-link",
          { staticClass: "primary--text", attrs: { to: "/login" } },
          [_vm._v("Go back to login")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }